import React from 'react';
import PropTypes from 'prop-types';
import { CheckIcon } from '@primer/octicons-react';
import { Collapse } from '@mui/material';

export default function WizardStep({
  children,
  step,
  index,
  isLastStep,
  onNext,
  disabledNext,
  onPrevious,
  disabledPrevious,
  onCancel,
  disabledCancel,
  hasIcon,
  nextLabel,
}) {
  const subComponents = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      active: step === index,
    }),
  );

  return (
    <div
      className="wizard-step"
      data-wizard-step-current={step === index}
      data-wizard-step-complete={step > index}
      data-wizard-last-step={isLastStep}
    >
      <div className="wizard-step-item d-flex">
        <div
          className="wizard-step-badge text-bold my-sm-1"
          data-wizard-step={index}
        >
          <CheckIcon className="wizard-step-check" />
        </div>
        <div className="wizard-step-body">
          <div
            className={
              hasIcon
                ? 'wizard-step-container wizard-step-container-icon'
                : 'wizard-step-container'
            }
          >
            {subComponents}
          </div>
          <Collapse in={step === index} timeout={500}>
            <div className="wizard-step-buttons">
              {onCancel && (
                <button
                  type="button"
                  className="btn-invisible btn mr-2"
                  onClick={onCancel}
                  disabled={disabledCancel}
                >
                  Cancelar
                </button>
              )}
              {onPrevious && (
                <button
                  type="button"
                  className="btn mr-2"
                  onClick={onPrevious}
                  disabled={disabledPrevious}
                >
                  Volver
                </button>
              )}
              {onNext && (
                <button
                  type="button"
                  className="btn-primary btn d-flex flex-items-center"
                  onClick={onNext}
                  disabled={disabledNext}
                >
                  {nextLabel || 'Continuar'}
                </button>
              )}
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  );
}

WizardStep.Icon = function Icon({ children, active }) {
  return (
    <Collapse in={active} timeout={500}>
      <div className="wizard-step-icon">{children}</div>
    </Collapse>
  );
};

WizardStep.Header = function Header({ children, active }) {
  if (active) return null;
  return (
    <div className="wizard-step-header">
      <h4 className="wizard-step-title">{children}</h4>
    </div>
  );
};

WizardStep.Content = function Content({ children, active }) {
  return (
    <Collapse in={active} timeout={500}>
      <div className="wizard-step-content">{children}</div>
    </Collapse>
  );
};

WizardStep.propTypes = {
  children: PropTypes.node.isRequired,
  step: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  isLastStep: PropTypes.bool,
  onNext: PropTypes.func,
  disabledNext: PropTypes.bool,
  onPrevious: PropTypes.func,
  disabledPrevious: PropTypes.bool,
  onCancel: PropTypes.func,
  disabledCancel: PropTypes.bool,
  hasIcon: PropTypes.bool,
  nextLabel: PropTypes.string,
};

WizardStep.Icon.propTypes = {
  children: PropTypes.node.isRequired,
  active: PropTypes.bool,
};

WizardStep.Header.propTypes = {
  children: PropTypes.any,
  active: PropTypes.bool,
};

WizardStep.Content.propTypes = {
  children: PropTypes.any,
  active: PropTypes.bool,
};
