/* eslint-disable no-nested-ternary */
import { useMutation, useQuery } from 'hooks';
import PropTypes from 'prop-types';
import { useCallback, useRef, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Link } from 'react-router-dom';
import {
  DeviceMobileIcon,
  DownloadIcon,
  LockIcon,
  ShieldCheckIcon,
  ShieldLockIcon,
  UnlockIcon,
  XIcon,
} from '@primer/octicons-react';
import { WizardStep } from 'components';
import { Backdrop, Fade, Modal } from '@mui/material';

export default function PasswordSettingsRoute({ user, refetch }) {
  const { addToast } = useToasts();
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [configureTwoFactorAuth, setConfigureTwoFactorAuth] = useState(false);
  const [applicationCode, setApplicationCode] = useState('');
  const [wizardStep, setWizardStep] = useState(1);
  const [displaySecretCode, setDisplaySecretCode] = useState(false);
  const [recoveryCodes, setRecoveryCodes] = useState([]);
  const appCodeInputRef = useRef(null);
  const [disable2FAText, setDisable2FAText] = useState('Habilitado');

  const { data: f2aData, loading: loadingQR } = useQuery({
    url: `/users/${user.id}/2fa`,
  });

  const onError = useCallback((error) =>
    addToast(error, { appearance: 'error', autoDismiss: true }),
  );

  const onCompleted = useCallback(() => {
    addToast('La contraseña ha sido actualizada', {
      appearance: 'success',
      autoDismiss: true,
    });
    setPassword('');
    setNewPassword('');
    setConfirmPassword('');
  });

  const [changePassword, { loading }] = useMutation({
    url: `/auth/change-password`,
    options: { method: 'POST' },
    onError,
    onCompleted,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (newPassword !== confirmPassword) {
      addToast('Las contraseñas no coinciden', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }
    changePassword({
      userId: user.id,
      oldPassword: password,
      newPassword,
    });
  };

  const onValidateCode = useCallback((data) => {
    addToast('La autentificación de doble factor ha sido configurada', {
      appearance: 'success',
      autoDismiss: true,
    });
    setRecoveryCodes(data.recoveryCodes);
    setWizardStep(3);
  }, []);

  const [validateCode, { loading: loadingValidateCode }] = useMutation({
    url: `/users/${user.id}/2fa`,
    options: { method: 'POST' },
    onError,
    onCompleted: onValidateCode,
  });

  const handleApplicationCode = (event) => {
    event.preventDefault();
    validateCode({
      code: applicationCode,
    });
  };

  const onCancleWizard = () => {
    setConfigureTwoFactorAuth(false);
    setWizardStep(1);
    setDisplaySecretCode(false);
    setRecoveryCodes([]);
    setApplicationCode('');
  };

  const downloadRecoveryCodes = (fromUser = false) => {
    const codes = fromUser ? user.twoFactorRecoveryCodes : recoveryCodes;
    const element = document.createElement('a');
    const file = new Blob([codes.join('\n')], {
      type: 'text/plain',
    });
    element.href = URL.createObjectURL(file);
    element.download = 'wallet-recovery-codes.txt';
    element.hidden = true;
    document.body.appendChild(element);
    element.click();
  };

  const handleFinishWizard = () => {
    onCancleWizard();
    setDisable2FAText('Habilitado');
    setTimeout(() => {
      refetch();
    }, 501);
  };

  const onDisable2FA = useCallback(() => {
    addToast('La autentificación de doble factor ha sido deshabilitada', {
      appearance: 'success',
      autoDismiss: true,
    });
    refetch();
  }, []);

  const [disable2FA, { loading: disabling2FA }] = useMutation({
    url: `/users/${user.id}/2fa`,
    options: { method: 'DELETE' },
    onError,
    onCompleted: onDisable2FA,
  });

  return (
    <div>
      <div className="Subhead hx_Subhead--responsive">
        <h2 className="Subhead-heading">
          Autentificación de doble factor (2FA)
        </h2>
        {user.twoFactorEnabled && (
          <div className="Subhead-actions">
            <button
              type="button"
              className={
                disable2FAText === 'Deshabilitar' || disabling2FA
                  ? 'btn btn-sm btn-danger'
                  : 'btn btn-sm btn-primary'
              }
              onMouseEnter={() => setDisable2FAText('Deshabilitar')}
              onMouseLeave={() => setDisable2FAText('Habilitado')}
              onClick={() => disable2FA()}
              disabled={disabling2FA}
            >
              {disable2FAText === 'Deshabilitar' || disabling2FA ? (
                <UnlockIcon />
              ) : (
                <LockIcon />
              )}{' '}
              {disabling2FA ? 'Deshabilitando…' : disable2FAText}
            </button>
          </div>
        )}
      </div>
      {!user.twoFactorEnabled && (
        <Modal
          open={configureTwoFactorAuth}
          onClose={onCancleWizard}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
        >
          <Fade in={configureTwoFactorAuth}>
            <div
              role="dialog"
              className="Box Box--overlay d-flex flex-column anim-fade-in fast my-3"
              aria-modal="true"
              style={{
                width: 720,
                height: 'auto',
                maxHeight: 'calc(100vh - 32px)',
              }}
            >
              <div className="Box-header">
                <button
                  type="button"
                  className="Box-btn-octicon btn-octicon float-right"
                  onClick={onCancleWizard}
                  aria-label="Cerrar dialogo"
                >
                  <XIcon />
                </button>
                <div className="Box-title">
                  Habilitar autentificación de doble factor
                </div>
              </div>
              <div
                className="Box-body scroller auto scrollerBase disableScrollAnchor"
                style={{
                  overflow: 'hidden scroll',
                }}
              >
                <div className="wizard">
                  <WizardStep
                    step={wizardStep}
                    index={1}
                    onNext={() => {
                      setWizardStep(2);
                      setTimeout(() => {
                        if (appCodeInputRef.current) {
                          appCodeInputRef.current.focus();
                        }
                      }, 501);
                    }}
                    onCancel={onCancleWizard}
                    hasIcon
                  >
                    <WizardStep.Icon>
                      <ShieldLockIcon size="large" />
                    </WizardStep.Icon>
                    <WizardStep.Header>
                      Autentificación de doble factor (2FA)
                    </WizardStep.Header>
                    <WizardStep.Content>
                      <div className="wizard-step-content-header">
                        <h3 className="wizard-step-title">
                          Autentificación de doble factor (2FA)
                        </h3>
                      </div>
                      <p>
                        La autentificación de doble factor (2FA) es una capa
                        adicional de seguridad que se utiliza al iniciar sesión
                        en sitios web o aplicaciones.
                      </p>
                      <div className="d-flex flex-items-center">
                        <span className="CircleBadge CircleBadge--small ml-2 mr-2 color-fg-muted">
                          <DeviceMobileIcon size="medium" />
                        </span>
                        <span className="h4">
                          Configurar mediante una aplicación
                        </span>
                      </div>
                      <span className="text-normal d-block pt-3">
                        Utilice una aplicación en su teléfono para obtener los
                        códigos de autentificación de doble factor cuando se le
                        solicite. Recomendamos utilizar aplicaciones TOTP
                        basadas en la nube como:{' '}
                        <a
                          href="https://support.google.com/accounts/answer/1066447?hl=en"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Google Authenticator
                        </a>
                        ,{' '}
                        <a
                          href="https://support.1password.com/one-time-passwords/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          1Password
                        </a>
                        ,{' '}
                        <a
                          href="https://authy.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Authy
                        </a>{' '}
                        o{' '}
                        <a
                          href="https://support.lastpass.com/help/lastpass-authenticator-lp030014"
                          target="_blank"
                          rel="noreferrer"
                        >
                          LastPass Authenticator
                        </a>
                        .
                      </span>
                    </WizardStep.Content>
                  </WizardStep>
                  <WizardStep
                    step={wizardStep}
                    index={2}
                    onCancel={onCancleWizard}
                    onNext={handleApplicationCode}
                    disabledNext={
                      applicationCode.length === 0 || loadingValidateCode
                    }
                  >
                    <WizardStep.Header>
                      Verificación de la aplicación
                    </WizardStep.Header>
                    <WizardStep.Content>
                      <div className="wizard-step-content-header">
                        <h3 className="wizard-step-title">
                          Verificación de la aplicación
                        </h3>
                      </div>
                      <p>
                        Escanee la siguiente imagen con la aplicación de
                        autentificación de doble factor de su teléfono. Si no
                        puedes utilizar un código QR, introduce{' '}
                        {displaySecretCode ? (
                          <span className="text-bold">{f2aData.secret}</span>
                        ) : (
                          <button
                            type="button"
                            className="btn-link"
                            onClick={() => setDisplaySecretCode(true)}
                          >
                            este código de texto
                          </button>
                        )}{' '}
                        en su lugar.
                      </p>
                      {!loadingQR && f2aData && (
                        <div className="user-select-none">
                          <img
                            className="border rounded-2 p-1 my-2"
                            src={f2aData.url}
                            alt="qr-code"
                            width={200}
                            draggable={false}
                          />
                        </div>
                      )}
                      <p className="text-bold">
                        Introduzca el código de la aplicación
                      </p>
                      <p>
                        Tras escanear la imagen del código QR, la aplicación
                        mostrará un código que puedes introducir a continuación.
                      </p>
                      <form onSubmit={handleApplicationCode}>
                        <input
                          ref={appCodeInputRef}
                          type="text"
                          className="form-control"
                          placeholder="XXXXXX"
                          value={applicationCode}
                          onChange={({ target }) =>
                            setApplicationCode(
                              target.value.trim().toUpperCase(),
                            )
                          }
                          disabled={loadingValidateCode}
                          autoComplete="off"
                          required
                        />
                      </form>
                    </WizardStep.Content>
                  </WizardStep>
                  <WizardStep
                    step={wizardStep}
                    index={3}
                    nextLabel="He guardado mis códigos de recuperación"
                    onNext={() => setWizardStep(4)}
                  >
                    <WizardStep.Header>
                      Guarde sus códigos de recuperación
                    </WizardStep.Header>
                    <WizardStep.Content>
                      <div className="wizard-step-content-header">
                        <h3 className="wizard-step-title">
                          Guarde sus códigos de recuperación
                        </h3>
                      </div>
                      <p>
                        Haga clic en Descargar para descargar sus códigos de
                        recuperación en su dispositivo. Guárdelos en un lugar
                        seguro porque sus códigos de recuperación pueden
                        ayudarle a volver a entrar en su cuenta si pierde el
                        acceso.
                      </p>
                      <div className="color-bg-subtle d-flex flex-items-center flex-justify-around p-3 color-fg-muted rounded-2">
                        <ul>
                          {recoveryCodes.slice(0, 5).map((code) => (
                            <li key={code}>
                              <span className="h5 color-fg-default">
                                {code}
                              </span>
                            </li>
                          ))}
                        </ul>
                        <ul>
                          {recoveryCodes.slice(5, 10).map((code) => (
                            <li key={code}>
                              <span className="h5 color-fg-default">
                                {code}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <button
                        type="button"
                        className="btn mt-2"
                        onClick={() => downloadRecoveryCodes(false)}
                      >
                        <DownloadIcon /> Descargar
                      </button>
                    </WizardStep.Content>
                  </WizardStep>
                  <WizardStep
                    step={wizardStep}
                    index={4}
                    nextLabel="Finalizar"
                    onNext={handleFinishWizard}
                    hasIcon
                    isLastStep
                  >
                    <WizardStep.Icon>
                      <ShieldCheckIcon size="large" />
                    </WizardStep.Icon>
                    <WizardStep.Header>
                      Autentificación de doble factor activada
                    </WizardStep.Header>
                    <WizardStep.Content>
                      <div className="wizard-step-content-header">
                        <h3 className="wizard-step-title">
                          Autentificación de doble factor activada
                        </h3>
                      </div>
                      <p>
                        La próxima vez que inicie sesión desde un navegador o
                        dispositivo no reconocido, deberá proporcionar un código
                        de autentificación de doble factor.
                      </p>
                    </WizardStep.Content>
                  </WizardStep>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      )}
      {user.twoFactorEnabled ? (
        <div>
          <p>
            La autentificación de doble factor añade una capa adicional de
            seguridad a tu cuenta al requerir algo más que una contraseña para
            iniciar sesión.
          </p>
          <button
            type="button"
            className="btn mt-2"
            onClick={() => downloadRecoveryCodes(true)}
          >
            <DownloadIcon /> Descargar códigos de recuperación
          </button>
        </div>
      ) : (
        <div className="blankslate color-bg-accent rounded-2">
          <LockIcon className="blankslate-icon" size="medium" />
          <h2 className="blankslate-heading">
            La autentificación de doble factor aún no esta habilitada
          </h2>
          <p>
            La autentificación de doble factor añade una capa adicional de
            seguridad a tu cuenta al requerir algo más que una contraseña para
            iniciar sesión.
          </p>
          <div className="blankslate-action">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setConfigureTwoFactorAuth(true)}
            >
              Habilitar autentificación de doble factor
            </button>
          </div>
        </div>
      )}
      <div className="Subhead Subhead--spacious hx_Subhead--responsive">
        <h2 className="Subhead-heading">Cambiar contraseña</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <div className="form-group-header">
            <label htmlFor="password">Contraseña actual</label>
          </div>
          <div className="form-group-body">
            <input
              type="password"
              className="form-control"
              id="password"
              name="old-password"
              placeholder="Contraseña actual"
              value={password}
              onChange={({ target }) => setPassword(target.value)}
              autoComplete="off"
              spellCheck={false}
              disabled={loading}
              required
            />
          </div>
        </div>
        <div className="form-group">
          <div className="form-group-header">
            <label htmlFor="new-password">Nueva contraseña</label>
          </div>
          <div className="form-group-body">
            <input
              type="password"
              className="form-control"
              id="new-password"
              name="new-password"
              placeholder="Nueva contraseña"
              value={newPassword}
              onChange={({ target }) => setNewPassword(target.value)}
              autoComplete="off"
              spellCheck={false}
              disabled={loading}
              required
            />
          </div>
        </div>
        <div className="form-group">
          <div className="form-group-header">
            <label htmlFor="confirm-password">Confirmar nueva contraseña</label>
          </div>
          <div className="form-group-body">
            <input
              type="password"
              className="form-control"
              id="confirm-password"
              name="confirm-new-password"
              placeholder="Confirmar nueva contraseña"
              value={confirmPassword}
              onChange={({ target }) => setConfirmPassword(target.value)}
              autoComplete="off"
              spellCheck={false}
              disabled={loading}
              required
            />
          </div>
        </div>
        <p className="note mb-3">
          Asegurese de que tiene al menos 15 caracteres.
          <br />O al menos 8 caracteres incluyendo un número y una letra.
        </p>
        <p>
          <button
            type="submit"
            className="btn btn-primary mr-2"
            disabled={loading}
          >
            {loading ? 'Actualizando contraseña…' : 'Actualizar contraseña'}
          </button>
          <Link to="/password-reset" className="btn btn-invisible">
            He olvidado mi contraseña
          </Link>
        </p>
      </form>
    </div>
  );
}

PasswordSettingsRoute.propTypes = {
  user: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};
