import { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useAccount } from 'context/account';
import { useMutation } from 'hooks';
import {
  AddOrganizationMemberModal,
  OrganizationMemberListItem,
} from 'components';
import { SearchIcon } from '@primer/octicons-react';
import PropTypes from 'prop-types';

export default function OrganizationAccessRoute({ organization, refetch }) {
  const history = useHistory();
  const { addToast } = useToasts();
  const { user: viewer } = useAccount();
  const [search, setSearch] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const onUpdateOrganization = useCallback(() => {
    addToast('Organización actualizada', {
      appearance: 'success',
      autoDismiss: true,
    });
    refetch();
    history.push(`/organizations/${organization.id}/access`);
  }, []);

  const onError = useCallback(
    (error) => addToast(error, { appearance: 'error', autoDismiss: true }),
    [],
  );

  const [updateOrganization, { loading }] = useMutation({
    url: `/organizations/${organization.id}`,
    options: { method: 'PATCH' },
    onCompleted: onUpdateOrganization,
    onError,
  });

  const addMember = (user) => {
    updateOrganization({
      users: [...organization.users.map((m) => m.id), user.id],
    });
  };

  const removeMember = (user) => {
    if (
      window.confirm(
        `¿Estás seguro de querer eliminar el acceso a ${user.name}?`,
      )
    ) {
      updateOrganization({
        users: organization.users
          .filter((u) => u.id !== user.id)
          .map((u) => u.id),
      });
    }
  };

  const addTeam = (team) => {
    updateOrganization({
      teams: [
        ...organization.teams.filter((t) => t.id !== team.id).map((t) => t.id),
        team.id,
      ],
    });
  };

  const removeTeam = (team) => {
    if (
      window.confirm(
        `¿Estás seguro de querer eliminar el acceso al equipo ${team.name}?`,
      )
    ) {
      updateOrganization({
        teams: organization.teams.filter(
          (t) => t.id.toString() !== team.id.toString(),
        ),
      });
    }
  };

  const filteredUsers = organization.users.filter((u) => {
    const regex = new RegExp(search, 'i');
    return u.name?.match(regex) || u.email?.match(regex);
  });

  const filteredTeams = organization.teams.filter((t) => {
    const regex = new RegExp(search, 'i');
    return t.name?.match(regex) || t.description?.match(regex);
  });

  return (
    <div className="access-content">
      <Helmet>
        <title>{`${organization.name}: Acceso • ${process.env.REACT_APP_NAME} Wallet`}</title>
      </Helmet>
      <div className="subnav d-flex mb-3 flex-row">
        <div className="flex-auto min-width-0">
          <div className="subnav-search ml-0">
            <SearchIcon className="subnav-search-icon" />
            <input
              type="search"
              className="form-control subnav-search-input subnav-search-input-wide width-full"
              placeholder="Buscar usuarios o equipos por su nombre…"
              value={search}
              onChange={({ target }) => setSearch(target.value)}
              spellCheck={false}
              autoFocus
            />
          </div>
        </div>
        {(viewer.role === 'admin' || viewer.id === organization.author?.id) && (
          <div className="ml-2">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setOpenModal(true)}
              disabled={loading}
            >
              Agregar <span className="d-none d-sm-inline-block">miembro</span>
            </button>
            <AddOrganizationMemberModal
              open={openModal}
              onClose={() => setOpenModal(false)}
              onAddUser={addMember}
              onAddTeam={addTeam}
              organization={organization}
              disabled={loading}
            />
          </div>
        )}
      </div>
      <div className="Box hx_Box--firstRowRounded0">
        <div className="Box-header d-flex flex-justify-between">
          <h3 className="Box-title">Miembros</h3>
        </div>
        <div className="member-list">
          {filteredTeams
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((team) => (
              <OrganizationMemberListItem
                key={team.id}
                member={team}
                onRemove={removeTeam}
                isTeam
              />
            ))}
          {filteredUsers
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((user) => (
              <OrganizationMemberListItem
                key={user.id}
                member={user}
                onRemove={removeMember}
                isAuthor={user.id === organization.author?.id}
              />
            ))}
          {filteredTeams.length === 0 && filteredUsers.length === 0 && (
            <div className="bg-primary blankslate blankslate-spacious text-center position-relative rounded-2">
              <img
                src="https://ghicons.github.com/assets/images/blue/png/Access%20and%20permission.png"
                alt="Access icon"
                className="d-block mb-3 mx-auto grayscale"
                draggable={false}
              />
              <h3 className="mb-1 color-fg-muted">
                No hay resultados que coincidan con su búsqueda
              </h3>
              <p className="container-sm color-fg-muted">
                Controle quién tiene acceso a los datos de una organización.
                Agregue equipos o usuarios a la organización para permitirles
                acceder a los datos.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

OrganizationAccessRoute.propTypes = {
  organization: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};
