import { useCallback } from 'react';
import {
  BellIcon,
  KeyIcon,
  LogIcon,
  PaintbrushIcon,
  PersonIcon,
  ShieldLockIcon,
  TrashIcon,
} from '@primer/octicons-react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { NavLink, Switch, Route, useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useMutation } from 'hooks';
import { useAccount } from 'context/account';

import ProfileSettingsRoute from './profile.settings.route';
import ThemeSettingsRoute from './theme.settings.route';
import NotificationSettingsRoute from './notifications.settings.route';
import PasswordSettingsRoute from './password.settings.route';
import CredentialSettingsRoute from './credential.settings.route';
import LogSettingsRoute from './logs.settings.route';

export default function UserSettingsRoute({ user, refetch }) {
  const history = useHistory();
  const { addToast } = useToasts();
  const { user: viewer } = useAccount();
  const onError = useCallback((error) =>
    addToast(error, { appearance: 'error', autoDismiss: true }),
  );

  const onCompleted = useCallback(() => {
    addToast('El usuario se ha eliminado', {
      appearance: 'success',
      autoDismiss: true,
    });
    history.push('/users');
  });

  const handleDeleteUser = (event) => {
    event.preventDefault();
    if (
      window.confirm(
        `¿Está seguro de que quiere eliminar el usuario ${user.name}? Se eliminará el acceso de los equipos y organizaciones. Cualquier credencial creada por este usuario permanecerá en la plataforma.`,
      )
    ) {
      deleteUser();
    }
  };

  const [deleteUser, { loading }] = useMutation({
    url: `/users/${user.id}`,
    options: { method: 'DELETE' },
    onError,
    onCompleted,
  });

  return (
    <div className="user-settings">
      <Helmet>
        <title>
          {user.name}: Ajustes • {process.env.REACT_APP_NAME} Wallet
        </title>
      </Helmet>
      <div className="Box">
        <div className="Box-body p-0">
          <div className="Layout">
            <div className="Layout-sidebar">
              <nav className="NavigationList p-2" aria-label="User settings">
                <ul className="ActionList">
                  <li className="ActionList-sectionDivider">
                    <h3 className="ActionList-sectionDivider-title">
                      Configuración
                    </h3>
                  </li>
                  <NavLink
                    to={`/users/${user.id}/settings`}
                    component="li"
                    className="ActionList-item"
                    activeClassName="ActionList-item--navActive"
                    exact
                  >
                    <NavLink
                      to={`/users/${user.id}/settings`}
                      className="ActionList-content ActionList-content--visual16"
                      exact
                    >
                      <span className="ActionList-item-visual ActionList-item-visual--leading">
                        <PersonIcon />
                      </span>
                      <span className="ActionList-item-label">
                        Perfil público
                      </span>
                    </NavLink>
                  </NavLink>
                  <NavLink
                    to={`/users/${user.id}/settings/theme`}
                    component="li"
                    className="ActionList-item"
                    activeClassName="ActionList-item--navActive"
                  >
                    <NavLink
                      to={`/users/${user.id}/settings/theme`}
                      className="ActionList-content ActionList-content--visual16"
                    >
                      <span className="ActionList-item-visual ActionList-item-visual--leading">
                        <PaintbrushIcon />
                      </span>
                      <span className="ActionList-item-label">Apariencia</span>
                    </NavLink>
                  </NavLink>
                  <NavLink
                    to={`/users/${user.id}/settings/notifications`}
                    component="li"
                    className="ActionList-item"
                    activeClassName="ActionList-item--navActive"
                  >
                    <NavLink
                      to={`/users/${user.id}/settings/notifications`}
                      className="ActionList-content ActionList-content--visual16"
                    >
                      <span className="ActionList-item-visual ActionList-item-visual--leading">
                        <BellIcon />
                      </span>
                      <span className="ActionList-item-label">
                        Notificaciones
                      </span>
                    </NavLink>
                  </NavLink>
                  <li role="separator" className="ActionList-sectionDivider" />
                  <li className="ActionList-sectionDivider">
                    <h3 className="ActionList-sectionDivider-title">Acceso</h3>
                  </li>
                  <NavLink
                    to={`/users/${user.id}/settings/password`}
                    component="li"
                    className="ActionList-item"
                    activeClassName="ActionList-item--navActive"
                  >
                    <NavLink
                      to={`/users/${user.id}/settings/password`}
                      className="ActionList-content ActionList-content--visual16"
                    >
                      <span className="ActionList-item-visual ActionList-item-visual--leading">
                        <ShieldLockIcon />
                      </span>
                      <span className="ActionList-item-label">
                        2FA y contraseña
                      </span>
                    </NavLink>
                  </NavLink>
                  <NavLink
                    to={`/users/${user.id}/settings/credentials`}
                    component="li"
                    className="ActionList-item"
                    activeClassName="ActionList-item--navActive"
                  >
                    <NavLink
                      to={`/users/${user.id}/settings/credentials`}
                      className="ActionList-content ActionList-content--visual16"
                    >
                      <span className="ActionList-item-visual ActionList-item-visual--leading">
                        <KeyIcon />
                      </span>
                      <span className="ActionList-item-label">
                        Acceso a credenciales
                      </span>
                    </NavLink>
                  </NavLink>
                  <li role="separator" className="ActionList-sectionDivider" />
                  <li className="ActionList-sectionDivider">
                    <h3 className="ActionList-sectionDivider-title">Archivo</h3>
                  </li>
                  <NavLink
                    to={`/users/${user.id}/settings/logs`}
                    component="li"
                    className="ActionList-item"
                    activeClassName="ActionList-item--navActive"
                  >
                    <NavLink
                      to={`/users/${user.id}/settings/logs`}
                      className="ActionList-content ActionList-content--visual16"
                    >
                      <span className="ActionList-item-visual ActionList-item-visual--leading">
                        <LogIcon />
                      </span>
                      <span className="ActionList-item-label">
                        Registro de seguridad
                      </span>
                    </NavLink>
                  </NavLink>
                  {viewer.role === 'admin' && (
                    <>
                      <li
                        role="separator"
                        className="ActionList-sectionDivider"
                      />
                      <li className="ActionList-sectionDivider">
                        <h3 className="ActionList-sectionDivider-title">
                          Administrador
                        </h3>
                      </li>
                      <li className="ActionList-item">
                        <button
                          type="button"
                          className="ActionList-content ActionList-content--visual16"
                          onClick={handleDeleteUser}
                          disabled={loading}
                        >
                          <span className="ActionList-item-visual ActionList-item-visual--leading">
                            <TrashIcon />
                          </span>
                          <span className="ActionList-item-label">
                            {loading
                              ? 'Eliminando usuario…'
                              : 'Eliminar usuario'}
                          </span>
                        </button>
                      </li>
                    </>
                  )}
                </ul>
              </nav>
            </div>
            <div className="Layout-main">
              <div className="pt-md-3 pb-3 pl-3 pl-md-0 pr-3">
                <Switch>
                  <Route
                    exact
                    path={`/users/${user.id}/settings`}
                    render={(props) => (
                      <ProfileSettingsRoute
                        {...props}
                        user={user}
                        refetch={refetch}
                      />
                    )}
                  />
                  <Route
                    path={`/users/${user.id}/settings/theme`}
                    render={(props) => (
                      <ThemeSettingsRoute
                        {...props}
                        user={user}
                        refetch={refetch}
                      />
                    )}
                  />
                  <Route
                    path={`/users/${user.id}/settings/notifications`}
                    render={(props) => (
                      <NotificationSettingsRoute
                        {...props}
                        user={user}
                        refetch={refetch}
                      />
                    )}
                  />
                  <Route
                    path={`/users/${user.id}/settings/password`}
                    render={(props) => (
                      <PasswordSettingsRoute
                        {...props}
                        user={user}
                        refetch={refetch}
                      />
                    )}
                  />
                  <Route
                    path={`/users/${user.id}/settings/credentials`}
                    render={(props) => (
                      <CredentialSettingsRoute
                        {...props}
                        user={user}
                        refetch={refetch}
                      />
                    )}
                  />
                  <Route
                    path={`/users/${user.id}/settings/logs`}
                    render={(props) => (
                      <LogSettingsRoute {...props} user={user} />
                    )}
                  />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

UserSettingsRoute.propTypes = {
  user: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};
