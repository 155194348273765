import { useState } from 'react';
import { AlertIcon, ShieldLockIcon } from '@primer/octicons-react';
import WhiteLogo from 'images/logo-white.png';
import { ReactComponent as Illustration } from 'images/banner-illustration.svg';
import { ReactComponent as IllustrationLight } from 'images/banner-illustration-light.svg';
import { useMutation } from 'hooks';
import { useAccountDispatch } from 'context/account';
import { Helmet } from 'react-helmet-async';
import { useHistory, useLocation, Link } from 'react-router-dom';

export default function SigninRoute() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAccountDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // const [isAuthenticatorAvailable, setIsAuthenticatorAvailable] =
  //   useState(false);

  // useLayoutEffect(async () => {
  //   const isAvailable =
  //     await window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
  //   setIsAuthenticatorAvailable(isAvailable);
  // });

  const handleLoginSuccess = (data) => {
    if (data.user.twoFactorEnabled) {
      return history.push({
        pathname: '/auth/two-factor',
        state: { from: location.state?.from, user: data.user },
      });
    }
    const { user, tokens } = data;
    localStorage.setItem('token', tokens.access.token);
    localStorage.setItem('tokenExpiresAt', tokens.access.expires);
    localStorage.setItem('refreshToken', tokens.refresh.token);
    localStorage.setItem('refreshTokenExpiresAt', tokens.refresh.expires);
    localStorage.setItem('viewerId', user.id);
    dispatch({ type: 'signin', payload: user });
    return history.push(location.state ? location.state.from.pathname : '/');
  };

  const [signInUser, { loading, error }] = useMutation({
    url: '/auth/login',
    options: { method: 'POST' },
    onCompleted: handleLoginSuccess,
  });

  const onSubmit = (event) => {
    event.preventDefault();
    signInUser({ email, password });
  };

  return (
    <div className="d-flex flex-1 position-relative color-bg-default">
      <Helmet>
        <title>Iniciar sesión • {process.env.REACT_APP_NAME} Wallet</title>
      </Helmet>
      <div
        className="container-md width-full d-flex flex-column flex-items-center flex-justify-center signin-content anim-fade-in pb-5"
        style={{ animationDuration: '1s' }}
      >
        <img
          src={WhiteLogo}
          className="user-select-none"
          alt="Sitelicon Ecommerce Services"
          width={200}
          draggable={false}
        />
        <div
          className="Box color-shadow-medium border-top-0 border-left-0 border-bottom-0 border-right-0 anim-fade-up"
          style={{ animationDelay: '250ms', animationDuration: '250ms' }}
        >
          <div className="Box-body signin-box">
            <div className="text-center" style={{ color: '#ff7700' }}>
              <ShieldLockIcon size="large" />
            </div>
            <h1 className="text-center">
              {process.env.REACT_APP_NAME}
              <span style={{ marginLeft: 4 }}>Wallet</span>
            </h1>
            <p className="text-center color-fg-muted">
              Mantenga sus datos seguros, de forma fácil y sencilla.
            </p>
            {error && (
              <p className="flash flash-error anim-scale-in">
                <AlertIcon />
                {error}
              </p>
            )}
            <form onSubmit={onSubmit}>
              <div className="form-group">
                <div className="form-group-body">
                  <input
                    id="email-input"
                    className="form-control input-hide-webkit-autofill"
                    type="email"
                    name="email"
                    placeholder="Introduza su dirección de correo"
                    value={email}
                    onChange={({ target }) => setEmail(target.value)}
                    aria-describedby="email-input-validation"
                    autoFocus
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-body">
                  <input
                    id="password-input"
                    className="form-control input-hide-webkit-autofill"
                    type="password"
                    name="password"
                    placeholder="Introduza su contraseña"
                    value={password}
                    onChange={({ target }) => setPassword(target.value)}
                    aria-describedby="password-input-validation"
                    required
                  />
                </div>
              </div>
              <div className="form-actions">
                <button
                  type="submit"
                  className="btn-mktg width-full mr-3"
                  disabled={loading}
                >
                  {loading ? 'Iniciando sesión…' : 'Iniciar sesión'}
                </button>
                <p className="mt-5 text-center">
                  ¿Has olvidado tu contraseña?{' '}
                  <Link to="/password-reset">Solicita una nueva</Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="signin-bg">
        <div className="banner-illustration">
          <div className="illustration-left anim-fade-in">
            <Illustration />
            <div className="illustration-light">
              <IllustrationLight />
            </div>
          </div>
          <div className="illustration-right anim-fade-in">
            <Illustration />
            <div className="illustration-light">
              <IllustrationLight />
            </div>
          </div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1903 556"
          fill="#fff"
        >
          <path
            className="svg-banner-shape"
            d="M753.1,434.2c110.6,63.7,277.7,70.6,373.4,15.4L1905,0v555.9H0V0.2L753.1,434.2z"
          ></path>
        </svg>
      </div>
    </div>
  );
}
