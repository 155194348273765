import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
    --app-height: 100%;
  }

  :root,
  [data-color-mode="light"][data-light-theme="light"],
  [data-color-mode="dark"][data-dark-theme="light"],
  [data-color-mode="light"][data-light-theme="dark"],
  [data-color-mode="dark"][data-dark-theme="dark"],
  [data-color-mode="light"][data-light-theme="dark_dimmed"],
  [data-color-mode="dark"][data-dark-theme="dark_dimmed"] {
    --color-fg-accent: #ff7700;
    /* --color-accent-emphasis: #ff7700; */
    --color-accent-fg: #ff7700;
    --color-btn-primary-border: #e66b00;
    --color-btn-primary-hover-border: #e66b00;
    --color-btn-primary-focus-border: #e66b00;
    --color-btn-primary-disabled-bg: #f3a773;
    --color-btn-primary-bg: #ff7700;
    --scrollbar-thin-thumb: rgba(79, 84, 92, 0.3);
    --scrollbar-thin-track: transparent;
    --scrollbar-auto-thumb: #ccc;
    --scrollbar-auto-track: #f2f2f2;
    --scrollbar-auto-scrollbar-color-thumb: #e3e5e8;
    --scrollbar-auto-scrollbar-color-track: #f2f3f5;
    /* --color-primer-shadow-focus: 0 0 0 3px rgba(218, 96, 9, 0.3); */
    --color-primer-border-active: #ff7700;
    --color-accent-subtle: #ffeddd;
    --color-accent-muted: rgba(255, 161, 84, 0.4);
  }

  [data-color-mode="dark"][data-dark-theme="dark"] {
    --color-btn-primary-disabled-bg: #af7248;
    --scrollbar-thin-thumb: #202225;
    --scrollbar-thin-track: transparent;
    --scrollbar-auto-thumb: #202225;
    --scrollbar-auto-track: #2e3338;
    --scrollbar-auto-scrollbar-color-thumb: #202225;
    --scrollbar-auto-scrollbar-color-track: #2f3136;
    --color-primer-border-active: #ff7700;
    --color-accent-subtle: #2d231b;
  }

  * {
    outline: none;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    height: 100%;
    max-height: 100vh;
    background-color: #f6f8fa;
    /* background-color: #202225; */
    @media not all and (hover:hover) {
      min-height: var(--app-height);
      max-height: var(--app-height);
    }
  }

  body {
    display: flex;
    flex-direction: column;
    color: #525666;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    overflow-x: hidden;
  }

  body,
  button,
  html,
  input,
  select,
  textarea {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    outline: none !important;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px var(--color-input-contrast-bg) inset;
    -webkit-background-color: var(--color-fg-default);
  }

  input:-webkit-autofill:focus,
  input:-webkit-autofill:active
  {
    -webkit-box-shadow: 0 0 0 50px var(--color-input-bg) inset, var(--color-state-focus-shadow);
  }

  table th,
  table td {
    padding: 12px 8px;
    text-align: left;
    /* border: 1px solid var(--color-border-muted); */
    border-top-color: var(--color-border-muted);
    border-top-style: solid;
    border-top-width: 1px;
  }

  table th {
    background-color: var(--color-bg-subtle);
  }

  table th:first-child,
  table td:first-child {
    padding-left: 16px;
  }

  table th:last-child,
  table td:last-child {
    padding-right: 16px;
  }

  table tr:first-child th {
    border-top-color: transparent;
  }

  table tr:hover td {
    background-color: var(--color-bg-accent)!important;
  }

  table tr:hover td:first-child {
    box-shadow: 2px 0 0 var(--color-border-accent-emphasis) inset;
  }

  .scrollerContent {
    overflow-anchor: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    min-height: 100%;
  }

  .scroller {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .scroller::-webkit-scrollbar-track {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .overlay .scroller::-webkit-scrollbar-track {
    margin-bottom: 0;
  }

  .scrollerInner {
    min-height: 0;
  }

  .scrollerSpacer {
    display: block;
    height: 30px;
    width: 1px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    pointer-events: none;
  }

  .overlay .scroller:after {
    height: 20px;
  }

  .scrollerWrap {
    overflow: hidden;
    height: auto;
  }

  .scrollerBase {
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    min-height: 0;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }

  .disableScrollAnchor {
    overflow-anchor: none;
  }

  .auto {
    scrollbar-width: auto;
  }

  .auto,
  .auto.fade:hover,
  .auto.scrolling {
    scrollbar-color: #e3e5e8 #f2f3f5;
  }

  .auto::-webkit-scrollbar {
    width: 16px;
    height: 16px;
  }

  .auto::-webkit-scrollbar-track {
    background-color: var(--scrollbar-auto-track);
  }

  .auto::-webkit-scrollbar-thumb,
  .auto::-webkit-scrollbar-track {
    border: 4px solid transparent;
    background-clip: padding-box;
    border-radius: 8px;
  }

  .auto::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-auto-thumb);
    min-height: 40px;
  }

  .auto::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  .text-bold {
    font-weight: 500 !important;
  }

  .btn-primary {
    background-color: #f66a0a;
  }

  .btn-primary:hover,
  .btn-primary.hover,
  [open]>.btn-primary {
    background-color: #e36209;
  }

  .btn-primary:focus,
  .btn-primary.focus {
    background-color: #d15704;
    box-shadow: rgba(255, 119, 0, 0.3) 0px 0px 0px 3px;
  }

  .btn-primary:disabled:hover {
    background-color: var(--color-btn-primary-disabled-bg);
  }

  .btn-mktg {
    display: inline-block;
    padding: 16px 24px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: #ff7700 !important;
    border: 1px solid #e66b00;
    border-radius: 6px;
    line-height: inherit;
    transition-duration: .2s !important;
    transition-property: background-color, border-color, box-shadow !important;
    -webkit-appearance: none;
    appearance: none;
  }

  .btn-mktg:hover {
    background: #f98c2c !important;
    border-color: #e66b00 !important;
    box-shadow: none !important;
  }

  .btn-mktg:focus,
  .btn-mktg.focus {
    box-shadow: rgba(255, 119, 0, 0.3) 0px 0px 0px 3px !important;
  }

  .btn-mktg:disabled,
  .btn-mktg.disabled,
  .btn-mktg[aria-disabled=true] {
    background-color: rgba(255, 119, 0, 0.5);
  }

  .btn-octicon:disabled,
  .btn-octicon:disabled:hover {
    color: var(--color-fg-muted);
    cursor: default;
  }

  .Box-row--focus-gray:hover,
  .Box-row--focus-gray:focus,
  .Box-row--focys-gray:active {
    /* background-color: var(--color-bg-subtle); */
    background-color: var(--color-bg-accent)!important;
    box-shadow: 2px 0 0 var(--color-border-accent-emphasis) inset;
  }

  .hx_Box--firstRowRounded0 .Box-row:first-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .Box-row:first-of-type {
    border-top-color: transparent;
  }

  .table-list-header-toggle .btn-link {
    color: var(--color-fg-muted);
  }

  .table-list-header-toggle .btn-link:hover {
    color: var(--color-fg-default);
    text-decoration: none;
  }

  .table-list-header-toggle .btn-link.selected, .table-list-header-toggle .btn-link.selected:hover {
    color: var(--color-fg-default);
    font-weight: 600;
  }

  .table-list-header-toggle .btn-link+.btn-link {
    margin-left: 10px;
  }

  .table-list-header-toggle .btn-link .octicon {
    margin-right: 4px;
  }

  .pagination a:focus:not([aria-disabled], [aria-current]),
  .pagination a:hover:not([aria-disabled], [aria-current]),
  .pagination em:focus:not([aria-disabled], [aria-current]),
  .pagination em:hover:not([aria-disabled], [aria-current]),
  .pagination span:focus:not([aria-disabled], [aria-current]),
  .pagination span:hover:not([aria-disabled], [aria-current]) {
    background-color: var(--color-canvas-default);
  }

  .subnav-search-icon {
    top: 8px;
  }

  .hx_underlinenav-item .UnderlineNav-octicon {
    margin-right: 6px;
  }

  .subnav-item {
    background-color: var(--color-canvas-default);
  }

  .subnav-item:focus,
  .subnav-item:hover {
    background-color: var(--color-bg-subtle);
    text-decoration: none;
  }

  .Label--info,
  .Label--accent {
    border-color: var(--color-border-accent-emphasis);
  }

  #sitelicon-wallet {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 100%;
    min-width: 100%;
  }

  .signin-content {
    position: relative;
    z-index: 11;
  }

  .signin-box {
    max-width: 480px;
    padding: 36px 56px 48px;
  }

  .signin-box .form-group {
    margin: 24px 0;
  }

  .signin-box .form-control {
    padding: 16px 24px;
  }

  .signin-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    min-height: 300px;
    max-height: 750px;
    background: linear-gradient(180deg, #ff7700, #ffd14d);
    user-select: none;
    z-index: 10;
  }

  .signin-bg > svg {
    position: absolute;
    left: -1px;
    right: -1px;
    bottom: -1px;
    float: left;
  }

  .svg-banner-shape {
    fill: var(--color-canvas-default);
  }

  .banner-illustration {
    position: absolute;
    top: auto;
    left: 50%;
    bottom: 384px;
    width: 80%;
    transform: translateX(-50%);
    opacity: 0.32;
    z-index: 20;
  }

  .illustration-left,
  .illustration-right {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 222px;
    height: 222px;
    top: -156px;
    z-index: 9;
  }

  .illustration-left {
    left: 0;
  }

  .illustration-right {
    right: 0;
  }

  .illustration-light {
    position: absolute;
    width: 222px;
    max-height: 242px;
    bottom: 0;
    z-index: 3;
  }

  .svg-illustration {
    height: 100%;
    max-width: 100%;
    position: relative;
    z-index: 3;
    overflow: visible !important;
  }

  .svg-illustration .opacity-3 {
    opacity: 0.7;
  }

  .svg-illustration--ssd .gradient-1 {
    fill: url(#gradient-ssd-1);
  }

  .svg-illustration--ssd .gradient-2 {
    fill: url(#gradient-ssd-2);
  }

  .svg-illustration .fill-dark-blue-1 {
    fill: #460101;
  }

  .svg-light__1 {
    opacity: .9;
    fill: url(#svg-gradient__light-1);
    enable-background: new;
  }

  .svg-light__2 {
    opacity: .74;
    fill: url(#svg-gradient__light-2);
    enable-background: new;
  }

  .svg-light__reflect {
    opacity: .24;
    fill: url(#svg-gradient__light-reflect);
    enable-background: new;
  }

  .sidebar {
    max-width: 320px;
  }

  .context-menu .overflow-y-scroll,
  .sidebar .overflow-y-scroll {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    -webkit-overflow-scrolling: touch;
  }

  .context-menu .overflow-y-scroll {
    padding: 6px 8px;
    padding-right: 0;
    min-height: 0;
  }

  .sidebar .overflow-y-scroll {
    padding: 0 16px 0 24px;
  }

  .context-menu .overflow-y-scroll,
  .context-menu .overflow-y-scroll:hover,
  .context-menu .overflow-y-scroll.scrolling,
  .sidebar .overflow-y-scroll,
  .sidebar .overflow-y-scroll:hover,
  .sidebar .overflow-y-scroll.scrolling {
    scrollbar-color: rgba(79,84,92,0.46) transparent;
  }

  .context-menu .overflow-y-scroll::-webkit-scrollbar,
  .sidebar .overflow-y-scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  .context-menu .overflow-y-scroll::-webkit-scrollbar-track,
  .sidebar .overflow-y-scroll::-webkit-scrollbar-track {
    border-color: transparent;
    background-color: transparent;
    border: 2px solid transparent;
  }

  .context-menu .overflow-y-scroll::-webkit-scrollbar-thumb,
  .sidebar .overflow-y-scroll::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 2px solid transparent;
    border-radius: 4px;
    background-color: rgba(79,84,92,0.46);
    min-height: 40px;
  }

  .context-menu .overflow-y-scroll::-webkit-scrollbar-corner,
  .sidebar .overflow-y-scroll::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  .context-menu .overflow-y-scroll::-webkit-scrollbar-thumb,
  .context-menu .overflow-y-scroll::-webkit-scrollbar-track,
  .sidebar .overflow-y-scroll::-webkit-scrollbar-thumb,
  .sidebar .overflow-y-scroll::-webkit-scrollbar-track {
    visibility: hidden;
  }

  .context-menu .overflow-y-scroll:hover::-webkit-scrollbar-thumb,
  .context-menu .overflow-y-scroll:hover::-webkit-scrollbar-track,
  .sidebar .overflow-y-scroll:hover::-webkit-scrollbar-thumb,
  .sidebar .overflow-y-scroll:hover::-webkit-scrollbar-track {
    visibility: visible;
  }

  .Header {
    padding: 14px 16px;
    color: var(--color-fg-default);
    background-color: var(--color-canvas-default);
  }

  .Header-link {
    margin-bottom: -1px;
    color: var(--color-fg-default);
    font-weight: 500;
    border-bottom: 2px solid transparent;
  }

  .Header-link:hover,
  .Header-link:focus {
    color: var(--color-fg-muted);
  }

  .Header-link.selected,
  .Header-link[aria-current]:not([aria-current=false]),
  .Header-link[role=tab][aria-selected=true] {
    border-bottom-color: #ff7700;
    outline: 1px dotted transparent;
    outline-offset: -1px;
  }

  .topic-box:hover .Link--primary,
  .topic-box:focus .Link--primary {
    color: var(--color-fg-accent) !important;
  }

  .credentials-list {
    overflow: auto;
  }

  .ColorSwatch {
    display: inline-block;
    width: 1em;
    height: 1em;
    vertical-align: middle;
    border: 1px solid rgba(27,31,35,.15);
    border-radius: 3px;
  }

  .Box--overlay {
    position: fixed;
    margin: 10vh auto;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    max-height: 80vh;
    max-width: 90vw;
    width: 448px;
    margin-right: auto;
    margin-left: auto;
    background-color: var(--color-canvas-default);
    background-clip: padding-box;
    border-color: var(--color-border-default);
    box-shadow: 0 0 18px rgb(0 0 0 / 40%);
  }

  .Details:not(.Details--on) .Details-content--hidden-not-important {
    display: none;
  }

  .jump-to-suggestions-results-container .navigation-item {
    border-bottom: 1px solid var(--color-border-muted);
  }

  .jump-to-suggestions-results-container .navigation-item:last-child {
    border-bottom: 0;
  }

  .jump-to-suggestions-results-container .navigation-item:last-child .jump-to-suggestions-path {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .jump-to-suggestions-path {
    min-width: 0;
    min-height: 44px;
    color: var(--color-fg-default);
  }

  .jump-to-suggestions-results-container .navigation-item:focus .jump-to-suggestions-path,
  .jump-to-suggestions-results-container .navigation-item[aria-selected=true] .jump-to-suggestions-path,
  .jump-to-suggestions-results-container .navigation-item:hover .jump-to-suggestions-path {
    color: #fff;
    background: #0366d6;
  }

  .jump-to-suggestions-path .jump-to-octicon {
    width: 28px;
    color: var(--color-fg-subtle);
  }

  .jump-to-suggestions-results-container .navigation-item:focus .jump-to-octicon,
  .jump-to-suggestions-results-container navigation-item[aria-selected=true] .jump-to-octicon,
  .jump-to-suggestions-results-container .navigation-item:hover .jump-to-octicon {
    color: #fff;
  }

  .jump-to-suggestions-path .jump-to-suggestion-name {
    max-width: none;
  }

  .suggestions-wrapper,
  .search-results {
    width: 100%;
    max-width: 560px;
  }

  .search-results {
    width: 100%;
    background-color: var(--color-canvas-default);
    border: 1px solid var(--color-border-default);
    border-radius: 10px;
    box-shadow: rgb(10 25 41 / 70%) 0px 4px 20px;
  }

  .search-results-close {
    display: block;
    align-self: center;
    padding: 4px 6px;
    background: none;
    color: var(--color-fg-muted);
    font-size: 10px;
    font-weight: 700;
    border-radius: 5px;
    border: 1px solid var(--color-fg-muted);
  }

  .search-results-tip {
    font-family: SFMono-Regular,Consolas,Liberation Mono,Menlo,Courier,monospace;
    text-shadow: rgb(10 25 41 / 70%) 1px 1px 1px;
  }

  .search-results-container {
    padding: 0 6px 12px 12px;
    /* min-height: 184px; */
    max-height: calc(100vh - 267px);
    overflow-y: auto;
    overflow-y: overlay;
  }

  .search-result-source {
    margin: 0 -4px;
    padding: 8px 4px 0;
    padding-top: 8px;
    color: var(--color-fg-muted);
    font-size: 10px;
    font-weight: 600;
    line-height: 32px;
    text-transform: uppercase;
  }

  .search-results-container .navigation-item:hover {

  }

  .search-results-container .navigation-item[aria-selected=true] {
    border-radius: 10px;
  }

  .search-results-container .navigation-item .jump-to-suggestions-path,
  .search-results-container .navigation-item:last-child .jump-to-suggestions-path {
    border-radius: 10px;
  }

  .search-results-container .jump-to-suggestions-path .jump-to-octicon {
    width: auto;
  }

  .search-results-container .navigation-item .jump-to-suggestions-path {
    padding: 10px 12px;
  }

  .search-results-container .navigation-item:hover .jump-to-suggestions-path {
    background-color: inherit;
    color: inherit;
  }

  .search-results-container .navigation-item[aria-selected=true] .jump-to-suggestions-path {
    background: #57606a;
    color: var(--color-fg-on-emphasis);
  }

  .search-results-container .navigation-item:hover .jump-to-suggestions-path .jump-to-octicon {
    color: inherit;
  }

  .search-results-container .navigation-item[aria-selected=true] .jump-to-suggestions-path .jump-to-octicon {
    color: var(--color-fg-on-emphasis);
  }

  .search-results-container .jump-to-suggestions-path .jump-to-octicon.jump-to-octicon-link {
    display: none;
    transform: rotateX(180deg);
  }

  .search-results-container .navigation-item[aria-selected=true] .jump-to-suggestions-path .jump-to-octicon.jump-to-octicon-link {
    display: block;
    color: rgba(255, 255, 255, 0.6);
  }

  .suggestions-wrapper .note > .Link {
    color: #72c1ff;
  }

  .AvatarStack-body .avatar {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border-right: 1px solid var(--color-canvas-default);
    box-shadow: none;
  }

  .AvatarStack-body:hover .avatar {
    margin-right: 2px;
  }

  .apexcharts-tooltip {
    background-color: var(--color-canvas-default) !important;
    border-color: var(--color-border-default) !important;
    box-shadow: var(--color-shadow-medium) !important;
  }

  .apexcharts-legend-marker {
    margin-right: 6px !important;
  }

  .user-settings .Layout {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
  }

  .user-settings .Layout .Layout-main,
  .user-settings .Layout .Layout-sidebar {
    width: 100%;
    grid-column: 1;
  }

  .qr-code-img {
    display: inline-block;
    padding: 16px;
    margin: 32px auto;
    background-color: #fff;
    border: 1px solid var(--color-border-muted);
    border-radius: 6px;
  }

  .wizard {
    position: relative;
  }

  .wizard-step-item {
    position: relative;
    padding: 8px 0;
    margin-left: 16px;
    flex-direction: row;
  }

  .wizard-step-item::before {
    position: absolute;
    top: 32px;
    bottom: 0;
    left: 0;
    display: block;
    width: 2px;
    height: 100%;
    content: "";
    background-color: var(--color-border-default);
  }

  .wizard-step[data-wizard-step-complete=true] .wizard-step-item::before {
    background-color: var(--color-accent-emphasis);
  }

  .wizard-step[data-wizard-last-step=true] .wizard-step-item::before {
    display: none;
    content: none;
  }

  .wizard-step-badge {
    position: relative;
    z-index: 1;
    display: flex;
    width: 32px;
    height: 32px;
    margin-right: 8px;
    margin-left: -16px;
    color: var(--color-fg-default);
    align-items: center;
    background-color: var(--color-border-default);
    border: 1px solid var(--color-canvas-default);
    border-radius: 50%;
    justify-content: center;
    flex-shrink: 0;
  }

  .wizard-step[data-wizard-step-current=true] .wizard-step-badge {
    color: var(--color-fg-on-emphasis);
    background-color: var(--color-accent-emphasis);
  }

  .wizard-step[data-wizard-step-complete=true] .wizard-step-badge {
    color: var(--color-fg-on-emphasis);
    background-color: var(--color-accent-emphasis);
  }

  .wizard-step[data-wizard-step-current=true][data-wizard-last-step=true] .wizard-step-badge {
    background-color: var(--color-success-emphasis);
  }

  .wizard-step:not([data-wizard-last-step=true]) .wizard-step-badge .wizard-step-check {
    display: none !important;
  }

  .wizard-step:not([data-wizard-last-step=true]) .wizard-step-badge::before {
    color: var(--color-fg-on-emphasis);
    content: attr(data-wizard-step);
  }

  .wizard-step-body {
    min-width: 0;
    max-width: 100%;
    color: var(--color-fg-default);
    flex: auto;
  }

  .wizard-step[data-wizard-step-current=true] .wizard-step-container {
    position: relative;
    background-color: var(--color-canvas-default);
    border: 1px solid var(--color-border-default);
    border-radius: 6px;
  }

  .wizard-step[data-wizard-step-current=true] .wizard-step-container::after,
  .wizard-step[data-wizard-step-current=true] .wizard-step-container::before {
    position: absolute;
    top: 11px;
    right: 100%;
    left: -8px;
    display: block;
    width: 8px;
    height: 16px;
    pointer-events: none;
    content: " ";
    -webkit-clip-path: polygon(0 50%, 100% 0, 100% 100%);
    clip-path: polygon(0 50%, 100% 0, 100% 100%);
  }

  .wizard-step[data-wizard-step-current=true] .wizard-step-container::before {
    background-color: var(--color-border-default);
  }

  .wizard-step[data-wizard-step-current=true] .wizard-step-container::after {
    margin-left: 2px;
    background-color: var(--color-canvas-default);
    background-image: linear-gradient(var(--color-canvas-default), var(--color-canvas-default));
  }

  .wizard-step[data-wizard-step-current=true] .wizard-step-container::before,
  .wizard-step[data-wizard-step-current=true] .wizard-step-container::after {
    transform: rotate(90deg);
  }

  .wizard-step[data-wizard-step-current=true] .wizard-step-container::before {
    position: absolute;
    top: -12px;
    right: 100%;
    left: 12px;
    display: block;
    width: 8px;
    height: 16px;
    pointer-events: none;
    content: " ";
    -webkit-clip-path: polygon(0 50%, 100% 0, 100% 100%);
    clip-path: polygon(0 50%, 100% 0, 100% 100%);
  }

  .wizard-step[data-wizard-step-current=true] .wizard-step-container::after {
    top: -10px;
    left: 11px;
  }

  .wizard-step[data-wizard-step-current=true]:not([data-wizard-last-step=true]) .wizard-step-container-icon::after {
    background-image: linear-gradient(var(--color-accent-subtle), var(--color-accent-subtle));
  }

  .wizard-step[data-wizard-step-current=true][data-wizard-last-step=true] .wizard-step-container-icon::after {
    background-image: linear-gradient(var(--color-success-subtle), var(--color-success-subtle));
  }

  .wizard-step-icon {
    display: flex;
    height: 96px;
    color: var(--color-accent-fg);
    background-image: linear-gradient(to right, var(--color-accent-subtle), var(--color-canvas-default));
    justify-content: center;
    align-items: center;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .wizard-step[data-wizard-last-step=true] .wizard-step-icon {
    color: var(--color-success-fg);
    background-image: linear-gradient(to right, var(--color-success-subtle), var(--color-canvas-default));
  }

  .wizard-step-header {
    padding-top: 4px;
    padding-left: 8px;
  }

  .wizard-step-header>.wizard-step-title {
    min-width: 0;
    margin-bottom: 4px;
    flex: 1 1 auto;
    color: var(--color-fg-muted);
  }

  .wizard-step[data-wizard-step-complete=true] .wizard-step-title {
    color: var(--color-fg-default);
  }

  .wizard-step-container .wizard-step-content {
    /* display: none; */
    width: 100%;
    padding: 16px 24px 24px 24px;
    overflow: visible;
    font-size: 14px;
  }

  /* .wizard-step[data-wizard-step-current=true] .wizard-step-container .wizard-step-content {
    display: block;
  } */

  .wizard-step[data-wizard-step-current=true] .wizard-step-container .wizard-step-content-header {
    margin-bottom: 16px;
  }

  .wizard-step[data-wizard-step-current=true] .wizard-step-container .wizard-step-title {
    color: var(--color-fg-default);
  }

  .wizard-step-body .wizard-step-buttons {
    display: flex;
    margin-top: 16px;
    justify-content: flex-end;
  }

  @media (min-width: 768px) {
    .user-settings .Layout {
      --Layout-sidebar-width: 230px;
      --Layout-gutter: 12px;
      grid-auto-flow: column;
      grid-template-columns: auto 0 minmax(0, calc(100% - var(--Layout-sidebar-width) - var(--Layout-gutter)));
    }

    .user-settings .Layout .Layout-sidebar {
      width: var(--Layout-sidebar-width);
      grid-column: 1;
    }

    .user-settings .Layout .Layout-main {
      grid-column: 2/span 2;
    }

    .wizard-step[data-wizard-step-current=true] .wizard-step-container::before,
    .wizard-step[data-wizard-step-current=true] .wizard-step-container::after {
      top: 11px;
      left: -8px;
      transform: rotate(0);
    }

    .wizard-step[data-wizard-step-current=true] .wizard-step-container::after {
      margin-left: 1px;
    }
  }
`;

export default GlobalStyle;
