import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { AccountProvider } from 'context/account';
import { ThemeProvider as ApplicationThemeProvider } from 'context/theme';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ToastProvider } from 'react-toast-notifications';
import { Toast as CustomToast, ToastContainer } from 'components';
import ApplicationRoutes from 'routes';
import { GlobalStyle, muiTheme } from 'utils';
import moment from 'moment';
import 'moment/locale/es';
import AdapterDateFns from '@date-io/moment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import reportWebVitals from './reportWebVitals';

moment.locale('es');

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} Wallet</title>
        <meta
          name="description"
          content={`${process.env.REACT_APP_NAME} Wallet es una forma segura de compartir contraseñas con otras personas.`}
        />
      </Helmet>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={{}}>
          <ApplicationThemeProvider>
            <MuiThemeProvider theme={muiTheme}>
              <ToastProvider
                components={{
                  Toast: CustomToast,
                  ToastContainer,
                }}
              >
                <AccountProvider>
                  <BrowserRouter>
                    <ApplicationRoutes />
                  </BrowserRouter>
                </AccountProvider>
              </ToastProvider>
            </MuiThemeProvider>
          </ApplicationThemeProvider>
          <GlobalStyle />
        </ThemeProvider>
      </LocalizationProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('sitelicon-wallet'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
